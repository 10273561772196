import { Table, TableBody, TableHead, TableCell, TableContainer, TableRow, FormGroup, FormControlLabel, Checkbox, TableSortLabel, makeStyles, Tooltip } from "@material-ui/core";
import { useContext, useState } from "react";
import { CATEGORY_COLORS } from '../../../Constants/CategoryColors';
import FilterListIcon from '@material-ui/icons/FilterList';
import CategoryLabel from "../../Translation/CategoryLabel";
import ElementLabel from "../../Translation/ElementLabel";
import Decimal from 'decimal.js';
import { FormattedMessage } from "react-intl";
import AlignmentIcon from "./Common/AlignmentIcon";
import { UserContext } from "../../../Context/UserContext";

const useStyles = makeStyles(() => ({
    label: {
        marginLeft: 0
    },
}));

export default function ResponseDistribution(props) {
    const classes = useStyles();
    const { user } = useContext(UserContext);
    const [sortBy, setSortBy] = useState('score');
    const [sortOrder, setSortOrder] = useState('desc');
    const [categories, setCategories] = useState({
        people: true,
        process: true,
        purpose: true,
        proactivity: true
    });

    const toggleSort = (column) => {
        // Toggle direction on already sorted by column
        if (sortBy === column) {
            setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
        } else {
            // Sort by new column, descending
            setSortBy(column);
            setSortOrder('desc');
        }
    };

    const toggleCategory = (category) => {
        // Don't allow the last category to be deselected
        const enabledCategories = Object.keys(categories).filter((current) => {
            return categories[current];
        });
        if (1 === enabledCategories.length && categories[category]) {
            return;
        }
        setCategories(prevState => ({
            ...prevState,
            [category]: !prevState[category]
        }));
    };

    if (!props.reportData.data) {
        return null;
    }

    const countBgColorStyle = (distr, key, hexBg) => {
        const style = {
            backgroundColor: hexBg,
            textAlign: 'center',
        };

        // Set transparency based on percentage of total
        const sum = Object.values(distr).reduce(function(a, b) { return a + b });
        const colorAlpha = 1 - distr[key] / sum;
        const hexAlpha = (255-parseInt((colorAlpha*255).toFixed(0))).toString(16);
        style['backgroundColor'] += hexAlpha.length === 1 ? '0'+hexAlpha : hexAlpha;
        return style;
    }

    const sortedData = () => {
        const dataCopy = JSON.parse(JSON.stringify(props.reportData.data ? props.reportData.data : []));

        if ('asc' === sortOrder) {
            if ('score' === sortBy || 'standardDeviation' === sortBy) {
                dataCopy.sort((a, b) => parseFloat(a[sortBy]) > parseFloat(b[sortBy]) ? 1 : -1);
            } else {
                dataCopy.sort((a, b) => a.distribution[sortBy] > b.distribution[sortBy] ? 1 : -1);
            }
        } else {
            if ('score' === sortBy || 'standardDeviation' === sortBy) {
                dataCopy.sort((a, b) => parseFloat(a[sortBy]) > parseFloat(b[sortBy]) ? 1 : -1).reverse();
            } else {
                dataCopy.sort((a, b) => a.distribution[sortBy] > b.distribution[sortBy] ? 1 : -1).reverse();
            }
        }
        return dataCopy;
    }

    return (
        <div ref={props.setScreenshotRef}>
            <FormGroup row style={{justifyContent: 'center'}}>
                <FormControlLabel
                    className={classes.label}
                    label={<CategoryLabel category="people"/>}
                    style={{backgroundColor: CATEGORY_COLORS['people'].light, borderLeft: '5px solid '+CATEGORY_COLORS['people'].dark, paddingRight: '10px'}}
                    control={
                        <Checkbox
                            color="primary"
                            style={{ padding: '5px' }}
                            checked={categories.people}
                            onChange={() => toggleCategory('people')}
                        />
                    }
                />
                <FormControlLabel
                    label={<CategoryLabel category="process"/>}
                    style={{backgroundColor: CATEGORY_COLORS['process'].light, borderLeft: '5px solid '+CATEGORY_COLORS['process'].dark, paddingRight: '10px'}}
                    control={
                        <Checkbox
                            color="primary"
                            style={{ padding: '5px' }}
                            checked={categories.process}
                            onChange={() => toggleCategory('process')}
                        />
                    }
                />
                <FormControlLabel
                    label={<CategoryLabel category="purpose"/>}
                    style={{backgroundColor: CATEGORY_COLORS['purpose'].light, borderLeft: '5px solid '+CATEGORY_COLORS['purpose'].dark, paddingRight: '10px'}}
                    control={
                        <Checkbox
                            color="primary"
                            style={{ padding: '5px' }}
                            checked={categories.purpose}
                            onChange={() => toggleCategory('purpose')}
                        />
                    }
                />
                <FormControlLabel
                    label={<CategoryLabel category="proactivity"/>}
                    style={{backgroundColor: CATEGORY_COLORS['proactivity'].light, borderLeft: '5px solid '+CATEGORY_COLORS['proactivity'].dark, paddingRight: '10px'}}
                    control={
                        <Checkbox
                            color="primary"
                            style={{ padding: '5px' }}
                            checked={categories.proactivity}
                            onChange={() => toggleCategory('proactivity')}
                        />
                    }
                />
            </FormGroup>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <FormattedMessage id="generic.category" defaultMessage={"Category"} />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="generic.element" defaultMessage={"Element"} />
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    IconComponent={FilterListIcon}
                                    active={sortBy === 'score'}
                                    direction={(sortBy === 'score' ? sortOrder : 'desc')}
                                    onClick={() => {toggleSort('score')}}
                                >
                                    <FormattedMessage id="generic.scorePercent" defaultMessage={"Score (%)"}/>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    IconComponent={FilterListIcon}
                                    active={sortBy === 'standardDeviation'}
                                    direction={(sortBy === 'standardDeviation' ? sortOrder : 'desc')}
                                    onClick={() => {toggleSort('standardDeviation')}}
                                >
                                    <FormattedMessage id="generic.alignment" defaultMessage={"Alignment"}/>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center" style={{}}>
                                <TableSortLabel
                                    IconComponent={FilterListIcon}
                                    active={sortBy === '1'}
                                    direction={(sortBy === '1' ? sortOrder : 'desc')}
                                    onClick={() => {toggleSort('1')}}
                                >
                                    20
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    IconComponent={FilterListIcon}
                                    active={sortBy === '2'}
                                    direction={(sortBy === '2' ? sortOrder : 'desc')}
                                    onClick={() => {toggleSort('2')}}
                                >
                                    40
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    IconComponent={FilterListIcon}
                                    active={sortBy === '3'}
                                    direction={(sortBy === '3' ? sortOrder : 'desc')}
                                    onClick={() => {toggleSort('3')}}
                                >
                                    60
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    IconComponent={FilterListIcon}
                                    active={sortBy === '4'}
                                    direction={(sortBy === '4' ? sortOrder : 'desc')}
                                    onClick={() => {toggleSort('4')}}
                                >
                                    80
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    IconComponent={FilterListIcon}
                                    active={sortBy === '5'}
                                    direction={(sortBy === '5' ? sortOrder : 'desc')}
                                    onClick={() => {toggleSort('5')}}
                                >
                                    100
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData().map((entity, index) => {
                            if (!categories[entity.category]) {
                                return null;
                            }

                            return (
                                <TableRow key={index} style={{borderBottom: '10px solid white', height: 50}}>
                                    <TableCell style={{borderLeft: '5px solid '+CATEGORY_COLORS[entity.category].dark, backgroundColor: CATEGORY_COLORS[entity.category].light}}>
                                        <CategoryLabel category={entity.category} />
                                    </TableCell>
                                    <TableCell style={{backgroundColor: CATEGORY_COLORS[entity.category].light}}>{entity.shortName ? <ElementLabel element={entity.shortName} /> : entity.title}</TableCell>
                                    <TableCell style={{backgroundColor: CATEGORY_COLORS[entity.category].light, fontWeight: 'bold'}} align="center">{entity.score ? <span title={entity.score}>{Decimal(entity.score).toFixed(1)}</span> : '-'}</TableCell>
                                    {user && user.role === 'admin' ?
                                        <TableCell style={{backgroundColor: CATEGORY_COLORS[entity.category].light}} align="center">
                                            <Tooltip title={entity.standardDeviation ? Decimal(entity.standardDeviation).toFixed(1) : '-'}>
                                                <span>{entity.standardDeviation ? <AlignmentIcon standardDeviation={entity.standardDeviation} /> : '-'}</span>
                                            </Tooltip>
                                        </TableCell>
                                    : null}
                                    <TableCell padding="none" style={countBgColorStyle(entity.distribution, '1', CATEGORY_COLORS[entity.category].dark)}>
                                        {(entity.distribution ? entity.distribution['1'] : '-')}
                                    </TableCell>
                                    <TableCell padding="none" style={countBgColorStyle(entity.distribution, '2', CATEGORY_COLORS[entity.category].dark)}>
                                        {(entity.distribution ? entity.distribution['2'] : '-')}
                                    </TableCell>
                                    <TableCell padding="none" style={countBgColorStyle(entity.distribution, '3', CATEGORY_COLORS[entity.category].dark)}>
                                        {(entity.distribution ? entity.distribution['3'] : '-')}
                                    </TableCell>
                                    <TableCell padding="none" style={countBgColorStyle(entity.distribution, '4', CATEGORY_COLORS[entity.category].dark)}>
                                        {(entity.distribution ? entity.distribution['4'] : '-')}
                                    </TableCell>
                                    <TableCell padding="none" style={countBgColorStyle(entity.distribution, '5', CATEGORY_COLORS[entity.category].dark)}>
                                        {(entity.distribution ? entity.distribution['5'] : '-')}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
